import { type ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { StatusChip } from '@components/statusChip';
import { getUtilityIcon } from 'utils/utilityTypeUtils/utilityTypeUtils';
import { convertToReadableDate } from 'utils/dateUtils/dateUtils';
import { numericFormatter } from 'react-number-format';
import { getDaysFromStatusLastUpdate } from 'utils/formatters/getDaysFromDate/getDaysFromDate';
import i18next from 'i18next';
import { IoIosArrowForward } from 'react-icons/io';
import Tippy from '@tippyjs/react';
import TooltipIconComponent from '@components/TooltipIconComponent/TooltipIconComponent';
import { useFeatureFlag } from 'context/FeatureFlagProvider/FeatureFlagProvider';
import { FEATURE_FLAGS_LIST } from 'lib/featureFlagsList';
import { ToBuildingViewButton } from '@components/ToBuildingViewButton';

interface AnomaliesTableColumnsReturnType {
  columns: ColumnDef<any, any>[];
}

const useGetDashboardTableColumns = (): AnomaliesTableColumnsReturnType => {
  const columnHelper = createColumnHelper<any>();
  const { hasFeatureFlagPermission } = useFeatureFlag();

  const columns = [
    columnHelper.accessor('utility_type', {
      header: '',
      enableSorting: false,
      cell: (info) => (
        <div className="fill-gray-50">{getUtilityIcon(info.getValue())}</div>
      ),
      size: 20,
    }),
    columnHelper.accessor('meter_name', {
      header: () => i18next.t('dashboardPage.table.meterName'),
      cell: (info) => (
        <Tippy content={info.getValue()} maxWidth={450}>
          <span className="max-w-[300px] desktop-l:max-w-[400px] block text-ellipsis overflow-hidden truncate">
            {info.renderValue()}
          </span>
        </Tippy>
      ),
      enableSorting: true,
      size: 300,
      maxSize: 400,
    }),
    columnHelper.accessor('asset_name', {
      header: () => i18next.t('dashboardPage.table.asset'),
      cell: (info) => {
        const id = info.row.original.asset_id;
        const name = info.row.original.asset_name;
        return name ? <ToBuildingViewButton name={name} id={id} /> : '-';
      },
    }),
    columnHelper.accessor('moment_change', {
      header: () => i18next.t('dashboardPage.table.momentOfChange'),
      cell: (info) => <span>{convertToReadableDate(info.renderValue())}</span>,
    }),
    columnHelper.accessor('status', {
      header: () => i18next.t('dashboardPage.table.status'),
      cell: (info) => <StatusChip status={info.getValue()} />,
    }),
    columnHelper.accessor('time_on_status', {
      header: () => i18next.t('dashboardPage.table.timeOnStatus'),
      cell: (info) => {
        return getDaysFromStatusLastUpdate(info.renderValue());
      },
    }),
    columnHelper.accessor('impact_total_euro', {
      id: 'impact_euro',
      header: () => (
        <div className="flex items-center gap-2">
          <span>{i18next.t('dashboardPage.table.totalImpact')}</span>
          <TooltipIconComponent
            content={i18next.t('dashboardPage.table.totalImpactTooltip')}
            pb={2}
          />
        </div>
      ),
      cell: (info) =>
        `${numericFormatter((info.getValue() ?? 0).toFixed(2), {
          decimalSeparator: ',',
          thousandSeparator: '.',
          decimalScale: 2,
        })} €`,
    }),
    columnHelper.accessor('impact_average_diff_euro', {
      id: 'impact_unit',
      header: () => (
        <div className="flex items-center gap-2">
          <span>{i18next.t('dashboardPage.table.impactPerDay')}</span>
          <TooltipIconComponent
            content={i18next.t('dashboardPage.table.impactPerDayTooltip')}
            pb={2}
          />
        </div>
      ),
      cell: (info) => {
        return `${numericFormatter((info.getValue() ?? 0).toFixed(2), {
          decimalSeparator: ',',
          thousandSeparator: '.',
          decimalScale: 2,
        })} €`;
      },
    }),
    columnHelper.accessor('', {
      id: 'action',
      header: () => '',
      cell: (info) =>
        !hasFeatureFlagPermission(FEATURE_FLAGS_LIST.project.get) ? (
          <IoIosArrowForward size={20} />
        ) : null,
      size: 20,
    }),
  ];

  return {
    columns,
  };
};
export default useGetDashboardTableColumns;
