export enum UtilityTypeEnum {
  ELECTRICITY = 'ELECTRICITY',
  WATER = 'WATER',
  GAS = 'GAS',
  THERMAL_ENERGY = 'THERMAL_ENERGY',
}

export enum UtilityTypeToUnitEnum {
  ELECTRICITY = 'kWh',
  WATER = 'm³',
  GAS = 'kWh',
}
