import axiosInstance from 'api/axios';
import { type AxiosError, type AxiosResponse } from 'axios';
import { type AssetResponse } from 'interfaces/assets/Asset.interface';
import { type ChangeTS } from 'interfaces/change/ChangeTS.interface';
import {
  type BuildingProjectsResponse,
  type ProjectListResponse,
  type ProjectResponse,
} from 'interfaces/projects/ProjectData.interface';
import { type SubOptionsResponse } from 'interfaces/request/SubOptionsResponse.interface';
import queryClient from 'lib/queryClient';
import {
  type InfiniteData,
  type UseInfiniteQueryResult,
  type UseMutationResult,
  type UseQueryResult,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { handleError } from 'utils/handleError/handleError';
import { handleSuccess } from 'utils/handleSuccess/handleSuccess';

interface CreateProjectPayload {
  name: string;
  description?: string;
  responsible?: number;
  meter: number;
  category?: string;
  savingsTarget?: number;
  investmentCostNumber: number;
  referencePeriod: {
    start: string | Date;
    end: string | Date;
  };
  implementationPeriod: {
    start: string | Date;
    end: string | Date;
  };
}

export const useListProjectsDashboard = ({
  query,
  asset,
  utilityType,
  orderBy,
}: {
  query?: string;
  asset?: number;
  utilityType?: string;
  orderBy?: string;
}): UseInfiniteQueryResult<InfiniteData<ProjectListResponse>> => {
  return useInfiniteQuery<ProjectListResponse>({
    queryKey: ['listDashboardProjects', query, utilityType, asset, orderBy],
    queryFn: async ({ pageParam = 1 }) => {
      const params = {
        page: pageParam,
        per_page: 20,
        utility_type: utilityType,
        asset_id: asset,
        query,
        order_by: orderBy,
      };
      if (!params.utility_type) {
        delete params.utility_type;
      }
      if (!params.asset_id) {
        delete params.asset_id;
      }
      if (!params.query) {
        delete params.query;
      }
      if (!params.order_by) {
        delete params.order_by;
      }
      const response = await axiosInstance.get('project/filter/dashboard', {
        params,
      });
      return response.data;
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => allPages.length + 1,
    refetchOnWindowFocus: false,
  });
};

export const useListProjectsBuildingView = (
  buildingId?: string
): UseQueryResult<BuildingProjectsResponse> => {
  return useQuery<BuildingProjectsResponse>({
    queryKey: ['lisBuildingViewProjects', buildingId],
    queryFn: async () => {
      if (!buildingId) return;
      const response = await axiosInstance.get(
        `project/filter/building/${buildingId}`
      );
      return response.data;
    },
  });
};

export const useListProjects = ({
  query,
  asset,
  utilityType,
  orderBy,
}: {
  query?: string;
  asset?: number;
  utilityType?: string;
  orderBy?: string;
}): UseInfiniteQueryResult<InfiniteData<ProjectListResponse>> => {
  return useInfiniteQuery<ProjectListResponse>({
    queryKey: ['listProjects', query, utilityType, asset, orderBy],
    queryFn: async ({ pageParam = 1 }) => {
      const params = {
        page: pageParam,
        per_page: 20,
        utility_type: utilityType,
        asset_id: asset,
        query,
        order_by: orderBy,
      };
      if (!params.utility_type) {
        delete params.utility_type;
      }
      if (!params.asset_id) {
        delete params.asset_id;
      }
      if (!params.query) {
        delete params.query;
      }
      if (!params.order_by) {
        delete params.order_by;
      }
      const response = await axiosInstance.get('project/filter', {
        params,
      });
      return response.data;
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => allPages.length + 1,
    refetchOnWindowFocus: false,
  });
};

export const useFetchProjectCategories = (): UseQueryResult<
  SubOptionsResponse[]
> => {
  return useQuery<SubOptionsResponse[]>({
    queryKey: ['getProjectCategories'],
    queryFn: async () => {
      const response = await axiosInstance.get(`project/categories`);
      return response.data;
    },
  });
};

export const useFetchProject = (
  id?: number | string
): UseQueryResult<ProjectResponse> => {
  return useQuery({
    queryKey: ['project', id],
    queryFn: async () => {
      if (!id) {
        return null;
      }
      const response = await axiosInstance.get(`project/${id}`);
      return response.data;
    },
    retry: 2,
  });
};

export const useFetchProjectTimeSeries = (
  id?: number | string
): UseQueryResult<ChangeTS[]> => {
  return useQuery<ChangeTS[]>({
    queryKey: ['projectTimeSeries', id],
    queryFn: async () => {
      if (!id) {
        return null;
      }
      const response = await axiosInstance.get(`project/${id}/ts`);
      return response.data;
    },
  });
};

export const useDeleteProject = (): UseMutationResult<
  any,
  Error | AxiosError,
  number | undefined
> => {
  return useMutation({
    mutationFn: async (id) => {
      if (!id) return;
      const response = await axiosInstance.delete(`project/${id}`);
      return response.data;
    },
    onError: (error: Error | AxiosError) => {
      handleError(error);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['listProjects'] });
      handleSuccess('Project deleted');
    },
  });
};

export const useCreateProject = (): UseMutationResult<
  AxiosResponse,
  Error | AxiosError,
  CreateProjectPayload
> => {
  return useMutation({
    mutationFn: async ({
      name,
      description,
      responsible,
      meter,
      category,
      savingsTarget,
      investmentCostNumber,
      referencePeriod,
      implementationPeriod,
    }) => {
      const response = await axiosInstance.post('project/', {
        name,
        description,
        responsible_user_id: responsible,
        meter_id: meter,
        category,
        savings_target: savingsTarget ? savingsTarget / 100 : 0,
        investment_cost: investmentCostNumber,
        reference_period_start: referencePeriod.start,
        reference_period_end: referencePeriod.end,
        implementation_period_start: implementationPeriod.start,
        implementation_period_end: implementationPeriod.end,
      });
      return response;
    },
    onError: (error: Error | AxiosError) => {
      handleError(error);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['listProjects'] });
      handleSuccess('Project created successfully');
    },
  });
};

export const useUpdateProject = (
  id?: number
): UseMutationResult<
  AssetResponse,
  Error | AxiosError,
  CreateProjectPayload
> => {
  return useMutation({
    mutationFn: async ({
      name,
      description,
      responsible,
      meter,
      category,
      savingsTarget,
      investmentCostNumber,
      referencePeriod,
      implementationPeriod,
    }) => {
      if (!id) return;
      const response = await axiosInstance.patch(`project/${id}`, {
        name,
        description,
        responsible_user_id: responsible,
        category,
        savings_target: savingsTarget ? savingsTarget / 100 : 0,
        investment_cost: investmentCostNumber,
        reference_period_start: referencePeriod.start,
        reference_period_end: referencePeriod.end,
        implementation_period_start: implementationPeriod.start,
        implementation_period_end: implementationPeriod.end,
      });
      return response.data;
    },
    onError: (error: Error | AxiosError) => {
      handleError(error);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['listProjects'] });
      await queryClient.invalidateQueries({ queryKey: ['project'] });

      handleSuccess('Project updated successfully');
    },
  });
};
