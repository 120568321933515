import { DownloadIcon } from '@components/icons/DownloadIcon';
import { ToBuildingViewButton } from '@components/ToBuildingViewButton';
import { UtilityChip } from '@components/utilityChip';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { convertToReadableDate } from 'utils/dateUtils/dateUtils';

interface DetailsHeaderProps {
  meterName?: string;
  momentOfDetection?: string;
  downloadHandler?: () => Promise<void>;
  isLoading?: boolean;
  utilityType?: string;
  building?: string;
  buildingId?: number;
}

const DetailsHeader: FC<DetailsHeaderProps> = ({
  meterName,
  momentOfDetection,
  downloadHandler,
  isLoading = false,
  utilityType,
  building,
  buildingId,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-row">
      <div className="basis-9/12">
        <div className="flex-col">
          <div className="flex gap-4 items-center min-h-[44px]">
            {isLoading ? (
              <Skeleton
                containerClassName="flex-1 min-w-[400px]"
                height={'28px'}
              />
            ) : (
              <h1 className="max-w-[300px] laptop:max-w-[600px] desktop:max-w-[1000px] text-ellipsis overflow-hidden ...">
                {meterName}
              </h1>
            )}

            {isLoading ? (
              <Skeleton
                containerClassName="flex-1"
                height={'28px'}
                width={'100px'}
                borderRadius={'20px'}
              />
            ) : (
              <UtilityChip utility={utilityType} />
            )}
          </div>
          <div className="mt-4 flex gap-2 text-base">
            {isLoading ? (
              <Skeleton width={'100px'} />
            ) : (
              building && <p>{t('common.mainBuilding')}:</p>
            )}
            {isLoading ? (
              <Skeleton width={'150px'} />
            ) : (
              <p>
                {building && buildingId && (
                  <ToBuildingViewButton name={building} id={buildingId} />
                )}
              </p>
            )}
          </div>
          <div className="mt-1 flex gap-2 text-base">
            {isLoading ? (
              <Skeleton width={'100px'} />
            ) : (
              <p>{t('anomaliesPage.momentOfChange')}:</p>
            )}
            {isLoading ? (
              <Skeleton width={'150px'} />
            ) : (
              <p>
                {momentOfDetection && convertToReadableDate(momentOfDetection)}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-row basis-3/12 justify-end flex-1">
        {isLoading ? (
          <Skeleton containerClassName="flex-1 max-w-[300px]" height={'28px'} />
        ) : (
          <div className="flex items-center justify-end gap-4 flex-1 h-fit">
            <DownloadIcon
              className="cursor-pointer"
              onClick={downloadHandler}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default DetailsHeader;
