import HChart from '@components/HChart/HChart';
import { UtilityTypeToUnitEnum } from 'enums/UtilityTypeEnum';
import type Highcharts from 'highcharts';
import { type MeterBarChartItem } from 'interfaces/assets/Building.interface';
import { type FC, useState } from 'react';
import { formatDecimalSeparator } from 'utils/formatters/number/numberUtils';

type GroupedData = Record<
  string,
  {
    y: number;
    x?: number;
    name: string;
    meterId: number;
    percentage: number;
    status: string;
    category: string;
  }[]
>;

interface MeterPoint extends Highcharts.Point {
  meterId: number;
  status: string;
}

interface BuildingSankeyAlternativeMeterChartProps {
  data?: MeterBarChartItem[];
  utilityType: string;
  barOnClick: ({
    meterId,
    meterName,
  }: {
    meterId: number;
    meterName: string;
  }) => void;
}

const ARROW_UP_ICON = `<svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <path
      d="M6.66699 6.0013V11.3346H5.33366V6.0013L0.666992 6.0013L6.00033 0.667969L11.3337 6.0013H6.66699Z"
      fill="#E92252"
    />
  </svg>`;

const ARROW_DOWN_ICON = `<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
  <path d="M6.4165 6.9974L6.4165 2.33073L7.58317 2.33073L7.58317 6.9974L11.6665 6.9974L6.99984 11.6641L2.33317 6.9974L6.4165 6.9974Z" fill="#8CD134"/>
</svg>`;

const BuildingSankeyAlternativeMeterChart: FC<
  BuildingSankeyAlternativeMeterChartProps
> = ({ data = [], utilityType, barOnClick }) => {
  const [, setChart] = useState<Highcharts.Chart | null>(null);

  const groupedData = data.reduce<GroupedData>((acc, ts) => {
    const category = ts?.category ?? 'No category';

    if (!acc[ts.category]) {
      acc[category] = [];
    }

    acc[category].push({
      y: ts.energy_use,
      name: ts.name,
      meterId: ts.id,
      percentage: ts.percentage,
      status: ts.color,
      category,
    });
    return acc;
  }, {});

  const allPoints = Object.values(groupedData).flat();
  const sortedPoints = allPoints.sort((a, b) => b.y - a.y);

  sortedPoints.forEach((point, index) => {
    point.x = index;
  });

  const sortedSeries: Highcharts.SeriesBarOptions[] = Object.keys(
    groupedData
  ).map((category) => ({
    name: category,
    data: sortedPoints
      .filter((point) => point.category === category)
      .map(({ category, ...point }) => point),
    type: 'bar',
  }));

  const options: Highcharts.Options = {
    chart: {
      zooming: {
        mouseWheel: {
          enabled: false,
        },
      },
      animation: false,
      type: 'bar',
      height: 500,
      scrollablePlotArea: {
        minHeight: Math.max(500, data.length * 50),
        scrollPositionY: 0,
      },
      events: {
        addSeries() {},
      },
    },
    tooltip: {
      pointFormatter() {
        return `<span style="color:${this.color as string}">\u25CF</span> ${
          this.series.name
        }: <b>${formatDecimalSeparator({
          value: this.y,
          decimalScale: 2,
          suffix: ` ${UtilityTypeToUnitEnum?.[
            utilityType as keyof typeof UtilityTypeToUnitEnum
          ]}`,
        })}</b><br/>`;
      },
    },
    rangeSelector: {
      enabled: false,
    },
    navigator: {
      enabled: false,
    },
    scrollbar: {
      enabled: false,
    },
    xAxis: {
      type: 'category',
      labels: {
        align: 'right',
        x: -10,
        style: {
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          width: 300,
        },
      },
    },
    yAxis: {
      opposite: true,
    },
    plotOptions: {
      bar: {
        animation: false,
        maxPointWidth: 50,
        groupPadding: 0.3,
        pointPadding: 0,
        colorByPoint: false,
        grouping: false,
        events: {
          click(e) {
            const point = e.point as MeterPoint;
            const meterId = point?.meterId;
            if (meterId) {
              const meterName = e.point.name;
              barOnClick({ meterId, meterName });
            }
          },
        },
      },
      series: {
        animation: false,
        dataLabels: {
          enabled: true,
          useHTML: true,
          formatter() {
            const point = this.point as MeterPoint;
            return `<div class="custom-label bg-white flex gap-1 rounded-sm">
                ${point.status === 'green' ? ARROW_DOWN_ICON : ARROW_UP_ICON}
              ${formatDecimalSeparator({
                value: this.percentage,
                decimalScale: 2,
                suffix: '%',
              })}
            </div>`;
          },

          x: -30,
          align: 'center',
          verticalAlign: 'middle',
          style: {
            padding: '0px',
            margin: '0px',
          },
        },
        events: {
          show() {
            const chart = this.chart;

            const hiddenSeries = chart.series.filter((s) => !s.visible);
            hiddenSeries.forEach((series) => {
              series.setVisible(true, false);
            });

            const visibleSeries = chart.series.filter((s) => s.visible);

            const allPoints: Highcharts.Point[] = [];
            visibleSeries.forEach((series) => {
              series.data.forEach((point) => {
                allPoints.push(point);
              });
            });

            const sortedPoints = allPoints.sort((a, b) => {
              if (b?.y && a?.y) return b.y - a.y;
              return 0;
            });

            const categories = sortedPoints.map((point) => point.name);
            chart.xAxis[0].setCategories(categories, false);

            sortedPoints.forEach((point, index) => {
              visibleSeries.forEach((series) => {
                const seriesPoint = series.data.find(
                  (p) => p.name === point.name
                );
                if (seriesPoint) {
                  seriesPoint.update({ x: index }, false);
                }
              });
            });

            chart.redraw();
          },
          hide() {
            const chart = this.chart;
            const visibleSeries = chart.series.filter((s) => s.visible);

            const visiblePoints = sortedPoints
              .filter((point) =>
                visibleSeries.some((s) => s.name === point.category)
              )
              .sort((a, b) => b.y - a.y);

            const categories = visiblePoints.map((point) => point.name);
            chart.xAxis[0].setCategories(categories, false);

            visiblePoints.forEach((point, index) => {
              visibleSeries.forEach((series) => {
                const seriesPoint = series.data.find(
                  (p) => p.name === point.name
                );
                if (seriesPoint) {
                  seriesPoint.update({ x: index }, false);
                }
              });
            });

            chart.redraw();
          },
        },
      },
    },
    series: sortedSeries || [],
    legend: {
      enabled: true,
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'top',
      itemMarginTop: 10,
      itemMarginBottom: 10,
    },
  };

  return (
    <div className="overflow-scroll">
      {data && <HChart options={options} callback={setChart} />}
    </div>
  );
};

export default BuildingSankeyAlternativeMeterChart;
