import { useFeatureFlag } from 'context/FeatureFlagProvider/FeatureFlagProvider';
import { FEATURE_FLAGS_LIST } from 'lib/featureFlagsList';
import { type FC } from 'react';
import { Link } from 'react-router-dom';

interface ToBuildingViewButtonProps {
  name: string;
  id: string | number;
}

const ToBuildingViewButton: FC<ToBuildingViewButtonProps> = ({ name, id }) => {
  const { hasFeatureFlagPermission } = useFeatureFlag();
  return hasFeatureFlagPermission(FEATURE_FLAGS_LIST.buildingView.get) ? (
    <Link
      className="text-tertiary-dark"
      to={`/app/assets/building/details/${id}`}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {name}
    </Link>
  ) : (
    <>{name}</>
  );
};
export default ToBuildingViewButton;
