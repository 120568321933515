import { IconTable } from '@components/table/IconTable';
import TooltipIconComponent from '@components/TooltipIconComponent/TooltipIconComponent';
import { useTheme } from 'context/ThemeProvider/ThemeProvider';
import { CardContainer } from 'layout/Card';
import { type FC, useEffect, useMemo, useState } from 'react';
import useGetMostImportantChangesTableColumns from './useGetBuildingNeedAttentionColumns';
import clsx from 'clsx';
import { SadFaceIcon } from '@components/icons/FaceIcon';
import { useListProjectsBuildingView } from 'api/projects/projectsServices';
import { type ProjectData } from 'interfaces/projects/ProjectData.interface';
import { useNavigate } from 'react-router-dom';
import { type ActionItem } from 'pages/BuildingViewPage/BuildingViewPage';

interface BuildingNeedAttentionTableProps {
  buildingId?: string;
  setIsActionRequired: React.Dispatch<React.SetStateAction<ActionItem[]>>;
}

const BuildingNeedAttentionTable: FC<BuildingNeedAttentionTableProps> = ({
  buildingId,
  setIsActionRequired,
}) => {
  const [hoveredId, setHoveredId] = useState<undefined | number>(undefined);
  const { columns } = useGetMostImportantChangesTableColumns();
  const { data, isFetching } = useListProjectsBuildingView(buildingId);
  const { theme } = useTheme();
  const navigate = useNavigate();

  const effectiveness = useMemo(() => {
    const projects = data?.needs_attention;

    if (projects && projects.length > 0) {
      return false;
    }
    return true;
  }, [data]);

  useEffect(() => {
    setIsActionRequired((prev) => {
      const updatedArray = prev.filter(
        (item) => item.name !== 'project_needs_attention'
      );
      return [
        ...updatedArray,
        { name: 'project_needs_attention', isActionRequired: !effectiveness },
      ];
    });
  }, [effectiveness]);

  const navigateToProject = (project: ProjectData): void => {
    if (project) navigate(`/app/management/projects/details/${project.id}`);
  };

  return (
    <div
      className={clsx(
        'rounded-lg',
        isFetching
          ? 'border border-gray-10'
          : effectiveness
          ? 'border border-success'
          : 'border border-error'
      )}
    >
      <CardContainer>
        <div className="flex items-center py-2 justify-between">
          <div className="flex items-center gap-4">
            <h3 className="font-bold text-base">Need attention</h3>
            <TooltipIconComponent content="TBD" />
          </div>
          {!isFetching && !effectiveness && <SadFaceIcon />}
        </div>
        <IconTable
          minHeight={200}
          skeletonAmountWhenLoading={3}
          isFetching={isFetching}
          enableRowBorder={false}
          stripped={false}
          onRowHover={(id) => {
            setHoveredId(id);
          }}
          onClickRow={navigateToProject}
          hoveredId={hoveredId}
          hoverColor={theme.colors['tertiary-lighter']}
          enableRowRoundedBorder={true}
          data={data?.needs_attention ?? []}
          columns={columns}
        />
      </CardContainer>
    </div>
  );
};
export default BuildingNeedAttentionTable;
