import TooltipIconComponent from '@components/TooltipIconComponent/TooltipIconComponent';
import { type FC, type ReactNode } from 'react';
import {
  getUtilityIcon,
  getUtilityTypeReadableName,
} from 'utils/utilityTypeUtils/utilityTypeUtils';
import { PiArrowDownFill } from 'react-icons/pi';
import { useTheme } from 'context/ThemeProvider/ThemeProvider';
import { type UtilityTypeEnum } from 'enums/UtilityTypeEnum';
import { SankeyButton } from './styled';

const getPercentage = (percentage?: number): ReactNode => {
  if (!percentage) return '-';
  return (
    <span className="flex items-center">
      <PiArrowDownFill fill="rgba(140, 209, 52, 1)" size={20} />
      <p
        className="text-lg"
        style={{ color: 'rgba(140, 209, 52, 1)' }}
      >{`${percentage}%`}</p>
    </span>
  );
};

interface BuildingSankeyButtonProps {
  id: string;
  utilityType: UtilityTypeEnum;
  value?: number;
  percentage?: number;
  unit: string;
  isSelected: boolean;
  handleSelection: (id: string) => void;
}

const BuildingSankeyButton: FC<BuildingSankeyButtonProps> = ({
  id,
  utilityType,
  value,
  percentage,
  unit,
  isSelected,
  handleSelection,
}) => {
  const { theme } = useTheme();

  return (
    <SankeyButton
      $isSelected={isSelected}
      onClick={() => {
        handleSelection(id);
      }}
      className="min-w-[200px]"
    >
      <div className="flex gap-2.5 text-base items-center">
        <div>{getUtilityIcon(utilityType, theme.colors['gray-50'])}</div>
        <div>{getUtilityTypeReadableName(utilityType)}</div>
      </div>
      <div className="flex items-center gap-2 justify-between">
        <p className="text-lg font-bold">{`${value ?? '-'} ${
          value ? unit : ''
        }`}</p>
        <p>{getPercentage(percentage)}</p>
        <TooltipIconComponent color={theme.colors['gray-45']} content="TBD" />
      </div>
    </SankeyButton>
  );
};
export default BuildingSankeyButton;
