import {
  useFetchBuildingSankey,
  useFetchBuildingView,
  useFetchMeterTimeseries,
} from 'api/assets/buildingViewService';
import { type FC, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { BuildingCard } from './components/BuildingCard';
import { BuildingOverallPerformance } from './components/BuildingOverallPerformance';
import { BuildingSankeyChart } from './charts/BuildingSankeyChart';
import { BuildingNeedAttentionTable } from './tables/BuildingNeedAttentionTable';
import { MostImportantChangesTable } from './tables/MostImportantChangesTable';
import { CardContainer } from 'layout/Card';
import { SingleSelectInput } from '@components/inputs/SelectInput/SingleSelectInput';
import { BuildingSankeyButton } from './components/BuildingSankeyButton';
import { UtilityTypeEnum } from 'enums/UtilityTypeEnum';
// import buildingViewMockData from './mock/buildingViewMock.json';
import { UnitTypeEnum } from 'enums/UnitTypeEnum';
import { BuildingViewMeterChart } from './charts/BuildingViewMeterChart';
import { ChartButton } from '@components/charts/ChartButton';
import { t } from 'i18next';
import { ProjectMeasuredVsExpectedChart } from 'pages/Management/ProjectDetailsPage/charts/ProjectMeasuredVsExpectedChart';
import { ExcessConsumptionCuSUMChart } from 'pages/Changes/ChangeDetailsPage/components/charts/ExcessConsumptionCuSUMChart';
import { BuildingSankeyAlternativeMeterChart } from './charts/BuildingSankeyAlternativeMeterChart';
import { useAuth } from 'hooks/contexts/AuthContext';
import { useFeatureFlag } from 'context/FeatureFlagProvider/FeatureFlagProvider';
import { FEATURE_FLAGS_LIST } from 'lib/featureFlagsList';
import { useNotFoundPage } from 'hooks/useNotFoundPage';

export enum GRADE {
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
  NONE = 'NONE',
}

const CHART_BUTTON_VALUES = {
  MEASURED: 'measured',
  CUSUM: 'cusum',
};

export interface ActionItem {
  name: string;
  isActionRequired: boolean;
}

const BuildingViewPage: FC = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const { hasFeatureFlagPermission } = useFeatureFlag();
  const { data, isFetching, error, isError } = useFetchBuildingView(id);
  const { validateAndRedirectToNotFound } = useNotFoundPage();
  const [isActionRequired, setIsActionRequired] = useState<ActionItem[]>([]);

  const [selectedSankeyDropdownOption, setSelectedSankeyDropdownOption] =
    useState('year');

  const [selectedSankeyOption, setSelectedSankeyOption] = useState(
    UtilityTypeEnum.ELECTRICITY as string
  );

  const { data: buildingChartsData } = useFetchBuildingSankey(
    id,
    selectedSankeyOption,
    selectedSankeyDropdownOption
  );

  const [activeChart, setActiveChart] = useState(CHART_BUTTON_VALUES.MEASURED);

  const [selectedMeter, setSelectedMeter] = useState<{
    id?: number;
    name?: string;
  }>({
    id: undefined,
    name: undefined,
  });
  const { data: meterTimeseriesData } = useFetchMeterTimeseries(
    selectedMeter?.id
  );

  const sankeyDropdownOptions = [
    { value: 'year', name: 'Last year (2024)' },
    { value: 'month', name: 'Last month' },
  ];

  const buttonList = [
    {
      label: t('anomaliesDetailsPage.buttons.measuredVsExcepted'),
      value: CHART_BUTTON_VALUES.MEASURED,
      tooltip: t('anomaliesDetailsPage.toolTips.measuredVsExcepted'),
    },
    {
      label: 'CuSUM',
      value: CHART_BUTTON_VALUES.CUSUM,
      tooltip: t('anomaliesDetailsPage.toolTips.CuSUM'),
    },
  ];

  const isDemoAccount =
    user?.company_name === 'Enersee Retail Store' ||
    user?.company_name === 'Demo Company';

  const handleSelectMeter = ({
    meterId,
    meterName,
  }: {
    meterId: number;
    meterName: string;
  }): void => {
    if (selectedMeter.id === meterId) {
      setSelectedMeter({ id: undefined, name: undefined });
      return;
    }
    setSelectedMeter({ id: meterId, name: meterName });
  };

  useEffect(() => {
    validateAndRedirectToNotFound(isError, error);
  }, [isError, error]);

  return (
    <div className="flex flex-col gap-4">
      {/* HEADER */}
      <div className="flex gap-4">
        <BuildingCard
          asset={data?.asset_impact}
          isFetching={isFetching}
          isActionRequired={isActionRequired}
        />
        <div className="w-3/12">
          <BuildingOverallPerformance
            building={data?.asset_impact}
            isFetching={isFetching}
            setIsActionRequired={setIsActionRequired}
          />
        </div>
      </div>
      {/* TABLES */}
      <div className="flex gap-4">
        {/* TOP CHANGES TABLE */}
        <div className="flex w-full flex-col gap-4">
          <div className="flex w-full justify-between items-center my-2">
            <h3 className="font-bold uppercase">TOP 3 DETECTED CHANGES</h3>
            <Link
              to={`/app/operations/anomalies?asset=${id ?? ''}`}
              className="text-base mr-6 text-tertiary-dark"
            >
              See all
            </Link>
          </div>
          <MostImportantChangesTable
            buildingId={id}
            setIsActionRequired={setIsActionRequired}
          />
        </div>
        {/* NEED ATTENTION PROJECTS TABLE */}
        {hasFeatureFlagPermission(FEATURE_FLAGS_LIST.project.list) ? (
          <div className="flex w-full flex-col gap-4">
            <div className="flex justify-between items-center my-2">
              <h3 className="font-bold uppercase">Projects</h3>
              <Link
                to={`/app/management/projects?asset=${id ?? ''}`}
                className="text-base mr-6 text-tertiary-dark"
              >
                See all
              </Link>
            </div>
            <BuildingNeedAttentionTable
              buildingId={id}
              setIsActionRequired={setIsActionRequired}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      <CardContainer gap={6}>
        <div className="flex justify-between items-center">
          <h3 className="font-bold uppercase">ENERGY OVERVIEW 2024</h3>
          <div className="flex gap-4 items-center">
            <p>Showing</p>
            <SingleSelectInput
              options={sankeyDropdownOptions}
              value={selectedSankeyDropdownOption}
              onChange={(value) => {
                setSelectedSankeyDropdownOption(value as string);
              }}
              maxWidth="250px"
              placeholder="Select a date"
            />
          </div>
        </div>
        <div className="flex gap-2">
          <BuildingSankeyButton
            id={UtilityTypeEnum.ELECTRICITY as string}
            isSelected={selectedSankeyOption === UtilityTypeEnum.ELECTRICITY}
            handleSelection={setSelectedSankeyOption}
            utilityType={UtilityTypeEnum.ELECTRICITY}
            value={isDemoAccount ? 422.0 : undefined}
            percentage={isDemoAccount ? 12 : undefined}
            unit={UnitTypeEnum.KWH}
          />
          <BuildingSankeyButton
            id={UtilityTypeEnum.GAS as string}
            isSelected={selectedSankeyOption === UtilityTypeEnum.GAS}
            handleSelection={setSelectedSankeyOption}
            utilityType={UtilityTypeEnum.GAS}
            value={isDemoAccount ? 976.0 : undefined}
            percentage={isDemoAccount ? 12 : undefined}
            unit={UnitTypeEnum.KWH}
          />
          <BuildingSankeyButton
            id={UtilityTypeEnum.WATER as string}
            isSelected={selectedSankeyOption === UtilityTypeEnum.WATER}
            handleSelection={setSelectedSankeyOption}
            utilityType={UtilityTypeEnum.WATER}
            value={isDemoAccount ? 4.0 : undefined}
            percentage={isDemoAccount ? 12 : undefined}
            unit={UnitTypeEnum.CUBIC_METER}
          />
        </div>
        {buildingChartsData?.use_alternative_chart ? (
          // ALTERNATIVE CHART
          <BuildingSankeyAlternativeMeterChart
            key={selectedSankeyOption}
            data={buildingChartsData?.asset_sankey?.meters}
            barOnClick={handleSelectMeter}
            utilityType={selectedSankeyOption}
          />
        ) : (
          // SANKEY CHART
          <BuildingSankeyChart
            data={buildingChartsData?.asset_sankey}
            id={id}
            utilityType={selectedSankeyOption}
            nodeOnClick={handleSelectMeter}
          />
        )}
        {/* METER SELECTED CHART */}
        <BuildingViewMeterChart meter={selectedMeter} />

        {selectedMeter?.id && (
          <div>
            <ChartButton
              buttonList={buttonList}
              activeChart={activeChart}
              handleActiveChart={setActiveChart}
              isLoading={false}
            />
            {activeChart === CHART_BUTTON_VALUES.MEASURED && (
              <ProjectMeasuredVsExpectedChart
                data={
                  meterTimeseriesData ? meterTimeseriesData.time_series : []
                }
                unit="undefined"
              />
            )}
            {activeChart === CHART_BUTTON_VALUES.CUSUM && (
              <ExcessConsumptionCuSUMChart
                data={
                  meterTimeseriesData ? meterTimeseriesData.time_series : []
                }
                unit=" "
              />
            )}
          </div>
        )}
      </CardContainer>
    </div>
  );
};
export default BuildingViewPage;
