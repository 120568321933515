import {
  useFetchChange,
  useFetchTimeSeries,
  useListDropdownChanges,
} from 'api/changes/changesServices';
import { type FC, useCallback, useEffect, useRef } from 'react';
import { AbsoluteImpact } from './components/AbsoluteImpact';
import { Activity } from './components/Activity';
import { ChangesOverview } from './components/ChangesOverview';
import { DetailsHeader } from './components/DetailsHeader';
import { ImpactContainer } from './components/ImpactContainer';
import { LessonsLearned } from './components/LessonsLearned';
import { RootCause } from './components/RootCause';
import { Status } from './components/Status';
import { downloadPDF } from 'utils/pdf/pdfUtils';
import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { BackButton } from '@components/backButton';
import { Mixpanel } from 'utils/mixpanel/mixpanel_agent';
import { useNotFoundPage } from 'hooks/useNotFoundPage';

const CHANGES_LIST_PATH = '/app/operations/anomalies';

const ChangesDetailsPage: FC = () => {
  const pdfRef = useRef<HTMLDivElement | null>(null);
  const { id } = useParams();

  Mixpanel.pageview();
  Mixpanel.track('anomaly_details', { change_id: id });

  const { data, isLoading, isError, error } = useFetchChange(id);

  const { data: timeSeriesData } = useFetchTimeSeries({
    id: data?.change.connection_point_id_client,
    momentChange: data?.change.moment_change ?? '',
  });

  const { validateAndRedirectToNotFound } = useNotFoundPage();

  const { data: dropdownData } = useListDropdownChanges({
    connectionPointIdClient: data?.change.connection_point_id_client,
  });

  useEffect(() => {
    validateAndRedirectToNotFound(isError, error);
  }, [isError, error]);

  const findPreviousAndNextChange = useCallback(
    (
      inputDate: string,
      datesArray: string[]
    ): { previous: string | null; next: string | null } => {
      const dates: { previous: string | null; next: string | null } = {
        previous: null,
        next: null,
      };

      const inputDateTime = new Date(inputDate).getTime();
      datesArray.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

      for (const dateStr of datesArray) {
        const dateDateTime = new Date(dateStr).getTime();
        if (dateDateTime < inputDateTime) {
          dates.previous = dateStr;
        } else if (dateDateTime > inputDateTime && dates.next === null) {
          dates.next = dateStr;
        }
      }

      if (
        dates.previous === null &&
        timeSeriesData &&
        timeSeriesData?.time_series.length > 0
      ) {
        dates.previous = timeSeriesData?.time_series[0].time;
      }

      return dates;
    },
    [dropdownData, timeSeriesData]
  );

  const previousAndNextDate = useMemo(() => {
    if (!data) return;
    const datesArray =
      dropdownData?.changes_detected?.map((change) => change.moment_change) ??
      [];
    return findPreviousAndNextChange(data?.change.moment_change, datesArray);
  }, [data, dropdownData, timeSeriesData]);

  return (
    <>
      <div className="flex flex-col gap-6" ref={pdfRef}>
        <BackButton
          navigateTo={CHANGES_LIST_PATH}
          goBackIfPreviousPage={['/app/operations/anomalies', '/app/dashboard']}
        />
        <DetailsHeader
          meterName={data?.change.connection_point_id}
          utilityType={data?.change.meter_unit.utility_type}
          momentOfDetection={data?.change.start_values_TS}
          building={data?.change.main_building?.name}
          buildingId={data?.change.main_building?.id}
          downloadHandler={async () => {
            await downloadPDF(pdfRef.current);
          }}
          isLoading={isLoading}
        />
        <ImpactContainer data={data?.change} isLoading={isLoading} />
        <ChangesOverview
          data={timeSeriesData}
          connectionPointId={data?.change.connection_point_id_client}
          unit={data?.change.meter_unit.unit_type}
          isLoading={isLoading}
          dropdownData={dropdownData}
        />
        <AbsoluteImpact
          changeData={data?.change}
          isLoading={isLoading}
          previousAndNextChange={previousAndNextDate}
        />
        <div className="flex gap-6">
          <div className="flex w-1/2">
            <RootCause
              rootCauseId={data?.change.root_cause_id}
              changeId={id}
              isLoading={isLoading}
            />
          </div>

          <div className="flex w-1/2 h-min">
            <Status
              users={data?.available_statuses.users}
              currentUser={data?.change.assigned_to_user?.id}
              currentStatus={data?.change.status}
              timeOnStatus={data?.change.time_on_status}
              statusList={data?.available_statuses.statuses}
              changeId={id}
              isLoading={isLoading}
            />
          </div>
        </div>
        <div className="flex gap-6">
          <div className="w-6/12">
            <Activity changeId={data?.change.id} isLoading={isLoading} />
          </div>
          <div className="w-6/12">
            <LessonsLearned changeId={id} isLoading={isLoading} />
          </div>
        </div>
      </div>
    </>
  );
};
export default ChangesDetailsPage;
