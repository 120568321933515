import { ActivityLog } from '@components/activityLog';
import { CardContainer, CardTitle } from 'layout/Card';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';

const Activity: FC<{
  changeId?: number;
  isLoading: boolean;
}> = ({ changeId, isLoading }) => {
  const { t } = useTranslation();
  return (
    <CardContainer style={{ minHeight: 350 }}>
      <CardTitle
        title={t('common.activity')}
        style={{ fontSize: 14 }}
        isLoading={isLoading}
      />

      <ActivityLog changeId={changeId} isLoading={isLoading} />
    </CardContainer>
  );
};
export default Activity;
