import {
  type ForwardedRef,
  type PropsWithChildren,
  forwardRef,
  useEffect,
  useState,
} from 'react';
import Map, {
  type MapLayerMouseEvent,
  type MapRef,
  type MapboxEvent,
  type ViewState,
  type ViewStateChangeEvent,
} from 'react-map-gl';
import * as turf from '@turf/turf';
import 'mapbox-gl/dist/mapbox-gl.css';

interface MapContainerProps extends PropsWithChildren {
  onZoomEnd?: ((e: ViewStateChangeEvent) => void) | undefined;
  onLoad?: ((e: MapboxEvent<undefined>) => void) | undefined;
  onClick?: ((e: MapLayerMouseEvent) => void) | undefined;
  coordinates: any[];
  height?: number;
  mapStyle?: string;
  fitBounds?: boolean;
  initialViewState?: {
    latitude: number;
    longitude: number;
    zoom: number;
  };
}

export const MAP_STYLES = {
  streets: 'mapbox://styles/mapbox/streets-v9',
  satellite: 'mapbox://styles/mapbox/satellite-v9',
};

const INITIAL_VIEWPORT = {
  latitude: 50.5039,
  longitude: 4.4699,
  zoom: 7,
};

const ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const MapContainer = forwardRef(
  (
    {
      children,
      onZoomEnd,
      onLoad,
      onClick,
      coordinates,
      height = 600,
      mapStyle = MAP_STYLES.streets,
      fitBounds = true,
      initialViewState,
    }: MapContainerProps,
    ref: ForwardedRef<MapRef>
  ) => {
    const [viewport, setViewport] = useState<Partial<ViewState>>(
      initialViewState ?? INITIAL_VIEWPORT
    );

    const handleViewportChange = (e: ViewStateChangeEvent): void => {
      setViewport(e.viewState);
    };

    const map = ref != null && typeof ref !== 'function' ? ref?.current : null;

    useEffect(() => {
      if (map && coordinates?.length > 0 && fitBounds) {
        const line = turf.lineString([...coordinates, []]);

        const [minLng, minLat, maxLng, maxLat] = turf.bbox(line);

        map?.fitBounds(
          [
            [minLng, minLat],
            [maxLng, maxLat],
          ],
          {
            padding: { top: 100, bottom: 100, left: 70, right: 70 },
            duration: 500,
          }
        );
      }
    }, [coordinates, map]);

    return (
      <Map
        {...viewport}
        ref={ref}
        attributionControl={false}
        mapboxAccessToken={ACCESS_TOKEN}
        initialViewState={INITIAL_VIEWPORT}
        style={{ width: '100%', height }}
        mapStyle={mapStyle}
        onZoomEnd={onZoomEnd}
        onLoad={onLoad}
        onClick={onClick}
        onMove={handleViewportChange}
        logoPosition={'bottom-right'}
      >
        {children}
      </Map>
    );
  }
);

export default MapContainer;
